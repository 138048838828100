import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1162.000000 831.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,831.000000) scale(0.100000,-0.100000)">
					<path d="M11351 4160 c0 -2466 3 -3810 9 -3800 12 19 12 7566 0 7595 -5 11 -9
-1658 -9 -3795z"/>
					<path d="M4960 7818 c-74 -13 -130 -55 -166 -125 l-32 -61 -37 45 c-50 60
-110 86 -196 86 -83 0 -157 -37 -198 -98 -16 -23 -136 -338 -279 -730 -138
-379 -523 -1439 -857 -2354 -333 -915 -610 -1682 -615 -1706 -21 -94 29 -198
119 -248 123 -69 281 -15 335 113 8 19 141 384 296 810 155 426 285 779 289
783 5 4 27 -18 51 -50 64 -85 291 -308 400 -392 334 -258 693 -446 1130 -591
182 -60 437 -127 597 -156 65 -11 94 -21 98 -33 4 -9 36 -97 72 -196 74 -205
90 -236 145 -275 131 -95 310 -35 368 124 l10 28 29 -39 c87 -121 257 -132
361 -25 74 76 88 162 46 276 l-23 66 31 4 c17 3 60 8 96 11 466 41 958 198
1278 408 393 257 610 595 629 977 18 375 -140 735 -477 1083 -379 393 -919
698 -1557 882 -377 109 -669 154 -1171 183 l-124 7 -196 538 c-207 566 -220
595 -293 632 -43 23 -108 32 -159 23z m190 -2658 c335 -921 607 -1676 605
-1678 -2 -2 -52 8 -112 23 -270 68 -582 187 -798 306 -549 302 -898 706 -975
1128 l-11 63 332 911 c182 502 334 916 336 920 3 5 7 7 9 4 2 -2 278 -757 614
-1677z m830 1110 c807 -61 1554 -367 2015 -825 131 -131 221 -253 295 -400 80
-161 110 -267 117 -420 9 -200 -36 -365 -147 -533 -243 -372 -749 -615 -1422
-686 -32 -3 -58 -4 -58 -2 0 2 -234 646 -520 1431 -286 785 -520 1431 -520
1436 0 12 74 11 240 -1z"/>
					<path d="M2970 2263 c-8 -3 -18 -11 -22 -17 -4 -6 -8 -202 -8 -436 0 -318 3
-429 12 -438 12 -12 95 -16 122 -6 9 3 16 17 16 30 0 31 6 30 59 -6 77 -53
177 -54 258 -4 38 24 55 44 83 98 35 68 35 70 35 200 0 123 -2 134 -28 188
-36 74 -85 114 -155 129 -65 13 -127 1 -184 -37 l-38 -24 0 155 0 155 -26 10
c-27 11 -99 12 -124 3z m338 -435 c28 -27 42 -77 42 -148 0 -73 -14 -117 -47
-152 -42 -44 -94 -37 -155 20 -27 25 -28 29 -28 127 0 101 0 101 33 132 58 57
109 63 155 21z"/>
					<path d="M2336 2197 c-16 -12 -268 -716 -283 -790 -4 -19 -1 -32 8 -38 17 -11
130 -12 147 -1 6 5 25 46 40 93 l29 84 153 0 153 0 29 -84 c15 -47 34 -88 40
-93 23 -14 154 -9 167 6 9 11 0 48 -43 173 -157 456 -226 647 -238 654 -21 14
-182 11 -202 -4z m148 -342 c25 -77 46 -143 46 -148 0 -4 -47 -7 -104 -7
l-104 0 51 155 c28 85 54 151 58 147 4 -4 27 -70 53 -147z"/>
					<path d="M3824 1996 c-95 -22 -142 -50 -149 -89 -6 -33 6 -80 23 -91 6 -3 35
6 66 20 68 31 153 42 196 24 22 -9 33 -23 41 -50 19 -62 12 -67 -93 -73 -109
-6 -170 -26 -216 -70 -83 -80 -59 -242 45 -293 22 -10 67 -20 108 -22 62 -3
76 -1 126 26 55 29 56 29 65 8 7 -17 19 -22 62 -24 87 -5 84 -14 80 262 -3
259 -6 273 -60 323 -56 53 -184 74 -294 49z m184 -410 c3 -31 -2 -44 -24 -67
-65 -68 -174 -50 -174 29 0 50 61 82 150 79 45 -2 45 -2 48 -41z"/>
					<path d="M5182 2000 c-59 -12 -140 -58 -167 -95 -61 -82 -85 -192 -66 -307 20
-124 76 -197 178 -234 52 -18 178 -17 242 2 60 17 132 78 161 134 29 56 43
147 36 228 -10 128 -61 209 -157 251 -53 24 -165 34 -227 21z m148 -159 c43
-31 55 -68 55 -166 0 -71 -4 -97 -19 -121 -52 -86 -179 -86 -220 0 -38 81 -26
236 23 280 42 39 114 42 161 7z"/>
					<path d="M5952 1989 c-24 -12 -51 -33 -60 -48 l-17 -25 -5 34 c-4 30 -9 35
-43 42 -22 4 -53 4 -70 0 l-32 -8 -3 -304 c-2 -236 1 -305 10 -312 15 -9 119
-11 142 -2 14 5 16 32 16 195 l0 189 42 46 42 46 57 -6 c53 -6 57 -5 63 16 10
39 7 113 -6 131 -22 28 -87 31 -136 6z"/>
					<path d="M4305 1992 c-22 -5 -31 -14 -33 -32 -2 -21 179 -526 210 -583 7 -14
1 -40 -26 -108 -43 -107 -45 -126 -11 -135 39 -10 134 1 150 17 20 20 276 779
273 809 -2 20 -10 26 -44 32 -22 5 -58 5 -79 0 l-39 -8 -60 -192 c-33 -106
-62 -192 -65 -192 -3 0 -35 84 -71 186 -36 102 -70 190 -76 195 -15 12 -93 19
-129 11z"/>
					<path d="M5646 834 c-8 -20 -8 -222 0 -251 4 -13 11 -23 18 -23 8 0 11 44 11
145 0 102 -3 145 -11 145 -7 0 -15 -7 -18 -16z"/>
					<path d="M6264 794 c-8 -69 1 -213 14 -226 21 -21 30 1 20 49 -5 27 -7 90 -4
141 5 76 4 92 -8 92 -10 0 -17 -17 -22 -56z"/>
					<path d="M7876 755 c5 -67 3 -99 -5 -109 -8 -10 -9 -16 -1 -21 5 -3 10 -19 10
-36 0 -38 27 -40 32 -1 3 26 5 27 76 30 96 4 92 22 -5 22 l-78 1 48 42 c26 23
47 45 47 50 0 16 -36 5 -62 -20 l-28 -27 0 45 c0 71 -11 119 -27 119 -12 0
-13 -16 -7 -95z"/>
					<path d="M3787 833 c-4 -3 -7 -32 -7 -64 0 -51 -2 -58 -22 -61 -32 -5 -35 -28
-4 -28 24 0 26 -4 26 -39 0 -50 8 -71 27 -71 12 0 13 10 8 55 -7 52 -6 55 19
66 33 15 33 29 1 29 -24 0 -25 3 -25 60 0 55 -6 70 -23 53z"/>
					<path d="M5228 738 c-4 -138 1 -188 18 -188 9 0 14 12 14 35 0 19 2 35 5 35 3
0 23 -9 45 -20 22 -11 46 -20 55 -20 30 0 14 22 -32 42 -27 11 -50 22 -52 24
-2 2 17 22 42 44 51 45 70 97 46 126 -15 19 -63 12 -91 -13 -17 -15 -18 -15
-18 10 0 17 -6 27 -14 27 -11 0 -15 -23 -18 -102z m117 52 c9 -15 -20 -63 -54
-88 l-31 -23 0 30 c0 20 10 40 31 60 32 33 44 37 54 21z"/>
					<path d="M6057 703 c1 -93 5 -138 12 -137 6 1 23 2 37 3 14 0 39 14 55 30 36
36 40 88 8 117 -19 17 -22 18 -50 2 l-29 -17 0 47 c0 58 -9 92 -24 92 -8 0
-11 -41 -9 -137z m99 -18 c10 -26 -6 -65 -31 -77 -37 -17 -41 -3 -17 47 22 46
38 56 48 30z"/>
					<path d="M7160 776 c0 -59 -2 -65 -22 -68 -31 -4 -35 -28 -4 -28 23 0 25 -4
28 -52 2 -40 7 -54 20 -56 14 -3 16 5 11 56 -5 57 -5 59 21 66 33 8 34 26 1
26 -24 0 -25 3 -25 60 0 47 -3 60 -15 60 -12 0 -15 -14 -15 -64z"/>
					<path d="M3190 824 c-12 -4 -15 -26 -13 -132 1 -109 3 -127 17 -126 9 0 31 1
50 2 82 4 119 91 63 149 -19 21 -24 32 -17 43 31 48 -26 84 -100 64z m70 -37
c0 -17 -20 -37 -37 -37 -7 0 -13 11 -13 25 0 20 5 25 25 25 14 0 25 -6 25 -13z
m18 -83 c44 -31 21 -94 -40 -109 l-28 -7 0 66 c0 62 1 66 23 66 12 0 33 -7 45
-16z"/>
					<path d="M4460 760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
					<path d="M5765 760 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
					<path d="M3438 734 c-7 -9 -24 -27 -37 -40 -20 -20 -21 -24 -7 -30 9 -4 16
-17 16 -33 0 -31 25 -71 45 -71 25 0 65 23 65 37 0 16 -22 17 -39 1 -10 -11
-15 -9 -27 6 -25 35 -18 54 26 61 44 7 51 25 24 63 -18 26 -48 29 -66 6z m38
-42 c-22 -5 -30 3 -21 18 5 8 11 7 22 -2 15 -12 14 -13 -1 -16z"/>
					<path d="M4356 729 c-23 -18 -27 -18 -32 -5 -3 9 -13 16 -21 16 -12 0 -14 -8
-9 -37 3 -21 6 -62 6 -90 0 -40 4 -53 15 -53 11 0 15 11 15 41 0 44 29 103 55
113 16 6 21 36 6 36 -4 0 -20 -9 -35 -21z"/>
					<path d="M4592 743 c-21 -8 -42 -60 -42 -103 0 -44 26 -80 59 -80 24 0 71 37
71 56 0 20 -20 17 -47 -7 -18 -18 -25 -20 -38 -9 -20 16 -19 69 1 98 15 21 17
22 33 8 29 -26 43 1 15 28 -16 16 -29 19 -52 9z"/>
					<path d="M4777 732 c-10 -10 -17 -24 -17 -30 0 -7 -7 -12 -15 -12 -8 0 -15 -7
-15 -15 0 -8 4 -15 9 -15 5 0 14 -20 20 -45 9 -35 17 -46 36 -51 26 -6 75 17
75 36 0 14 -33 13 -47 -1 -17 -17 -33 -2 -33 31 0 24 5 29 37 37 45 10 50 22
23 56 -25 31 -50 34 -73 9z m51 -30 c2 -7 -5 -12 -17 -12 -14 0 -19 5 -14 15
6 17 25 15 31 -3z"/>
					<path d="M5477 732 c-10 -10 -17 -24 -17 -30 0 -7 -7 -12 -15 -12 -8 0 -15 -7
-15 -15 0 -8 4 -15 9 -15 5 0 14 -20 20 -45 9 -35 17 -46 36 -51 32 -8 83 22
66 39 -9 9 -17 8 -32 -2 -24 -14 -34 -8 -43 30 -5 24 -2 27 36 35 50 11 55 22
28 57 -25 31 -50 34 -73 9z m51 -30 c2 -7 -5 -12 -17 -12 -14 0 -19 5 -14 15
6 17 25 15 31 -3z"/>
					<path d="M6407 732 c-10 -10 -17 -24 -17 -30 0 -7 -7 -12 -15 -12 -8 0 -15 -7
-15 -15 0 -8 4 -15 8 -15 4 0 13 -20 20 -45 9 -34 18 -46 37 -51 32 -8 83 22
66 39 -9 9 -17 8 -32 -2 -24 -14 -34 -8 -43 30 -5 24 -2 27 37 35 34 8 41 13
39 29 -5 27 -31 55 -52 55 -9 0 -24 -8 -33 -18z m51 -30 c2 -7 -5 -12 -17 -12
-14 0 -19 5 -14 15 6 17 25 15 31 -3z"/>
					<path d="M6727 733 c-8 -29 7 -166 19 -170 6 -2 18 23 27 55 18 66 42 96 58
71 5 -8 9 -25 9 -39 1 -56 13 -100 28 -100 12 0 14 11 8 71 -3 39 -11 79 -17
90 -14 26 -64 25 -83 -2 -14 -21 -14 -21 -18 7 -4 32 -24 43 -31 17z"/>
					<path d="M6977 732 c-10 -10 -17 -24 -17 -30 0 -7 -7 -12 -15 -12 -8 0 -15 -7
-15 -15 0 -8 4 -15 8 -15 4 0 13 -20 20 -45 9 -34 18 -46 36 -51 29 -7 66 11
66 31 0 18 -21 20 -38 3 -15 -15 -28 -3 -37 33 -6 24 -3 27 37 35 35 8 42 13
40 29 -5 27 -31 55 -52 55 -9 0 -24 -8 -33 -18z m51 -30 c2 -7 -5 -12 -17 -12
-14 0 -19 5 -14 15 6 17 25 15 31 -3z"/>
					<path d="M7437 743 c-4 -3 -7 -27 -7 -52 0 -44 -1 -45 -16 -23 -18 26 -36 28
-54 7 -17 -21 -30 -9 -30 26 0 17 -6 32 -15 36 -23 8 -24 3 -14 -52 5 -28 11
-65 14 -82 8 -46 32 -42 45 7 6 22 15 40 21 40 6 0 18 -18 26 -40 16 -39 40
-53 47 -27 3 6 7 47 9 90 4 58 2 77 -7 77 -7 0 -16 -3 -19 -7z"/>
					<path d="M7776 729 c-21 -17 -26 -18 -26 -5 0 9 -7 16 -15 16 -12 0 -15 -17
-15 -90 0 -73 3 -90 15 -90 11 0 15 11 15 35 0 42 18 81 54 114 14 13 23 28
20 32 -8 14 -19 11 -48 -12z"/>
					<path d="M3612 728 c-18 -18 -14 -46 13 -86 33 -49 32 -55 -10 -48 -27 5 -35
4 -35 -7 0 -27 65 -38 88 -15 19 19 14 45 -14 88 -15 22 -24 44 -20 48 4 4 12
-1 17 -11 12 -20 39 -23 39 -4 0 18 -32 47 -51 47 -8 0 -20 -5 -27 -12z"/>
					<path d="M4100 610 c0 -109 2 -130 15 -130 9 0 15 9 15 23 0 37 12 67 25 67
19 0 62 55 70 89 3 17 1 39 -5 52 -10 18 -18 21 -51 16 -21 -3 -39 -1 -39 4 0
5 -7 9 -15 9 -13 0 -15 -21 -15 -130z m90 62 c0 -29 -24 -72 -41 -72 -12 0
-12 56 1 81 16 29 40 24 40 -9z"/>
					<path d="M5901 705 c-34 -39 -44 -68 -39 -111 4 -42 44 -46 75 -7 l23 28 0
-27 c0 -19 5 -28 16 -28 14 0 16 10 12 71 -4 45 -11 75 -19 80 -10 6 -11 9 -1
9 6 0 12 5 12 10 0 24 -50 8 -79 -25z m33 -46 c-9 -24 -23 -51 -30 -59 -12
-12 -14 -11 -14 9 0 25 14 57 38 84 23 26 25 15 6 -34z"/>
					<path d="M7564 732 c-21 -14 -34 -51 -34 -96 0 -52 19 -76 59 -76 42 0 65 32
65 90 0 34 -6 56 -19 70 -18 20 -51 26 -71 12z m44 -34 c14 -14 16 -76 3 -96
-5 -8 -17 -12 -27 -10 -23 4 -31 65 -14 98 12 23 21 25 38 8z"/>
					<path d="M4456 714 c-15 -40 -3 -144 18 -144 8 0 11 26 11 80 0 53 -4 80 -11
80 -7 0 -15 -7 -18 -16z"/>
					<path d="M5760 655 c0 -59 3 -77 15 -82 9 -3 17 -4 18 -2 1 2 1 39 -1 82 -3
58 -7 77 -18 77 -11 0 -14 -17 -14 -75z"/>
					<path d="M4959 613 c-1 -4 -1 -12 0 -17 1 -4 -5 -19 -14 -32 -11 -17 -12 -25
-3 -30 15 -10 50 34 46 59 -3 20 -27 37 -29 20z"/>
					<path d="M8305 590 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
					<path d="M8420 590 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
					<path d="M8535 590 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
					<path d="M290 307 c0 -7 17 -23 39 -35 l39 -22 5438 0 5438 0 43 21 c24 11 43
27 43 35 0 12 -775 14 -5520 14 -4557 0 -5520 -2 -5520 -13z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
